export const const_data = {
  group_max_length: 50,
  REFETCH_DURATION_IN_MINUTES: 10,
  EMAIL_BODY_MAX_LENGTH: 5000,
};

export const SECTION_TYPES = {
  ALL_LEADS: 3,
  ALL_CUSTOMERS: 4,
  SEGMENTS: 2,
  BOOKING_LISTING: 5,
  SUBSCRIPTION_LISTING: 6,
  EXTRAS: 7,
  REJECTED: 1,
  BULK_ACTION_RECIPIENT: 11, //@DEV this is created to implement bulk actions & this is used to treat this section_type as recipient group.
};

export const SEARCH_TYPES = {
  EMAIL: 1,
  LISTING_TITLE: 2,
  SEGMENT_TITLE: 3,
  SUBSCRIPTION_TITLE: 4,
  // added custom enums for all leads and all customers
  // read handleSearchItem for explanation
  ALL_LEADS: 5,
  ALL_CUSTOMERS: 6,
};

export const SESSION_STORAGE_KEYS = {
  RECIPIENTS_DATA: "recipients_data",
  RECIPIENTS_TIMESTAMP: "recipients_timestamp",
};

export const GROUPS_SECTIONS = [
  SECTION_TYPES.ALL_CUSTOMERS,
  SECTION_TYPES.ALL_LEADS,
  SECTION_TYPES.BOOKING_LISTING,
  SECTION_TYPES.SUBSCRIPTION_LISTING,
  SECTION_TYPES.SEGMENTS,
  SECTION_TYPES.BULK_ACTION_RECIPIENT,
];

export const DELETION_BASIS = {
  [SECTION_TYPES.ALL_CUSTOMERS]: "user_type",
  [SECTION_TYPES.ALL_LEADS]: "user_type",
  [SECTION_TYPES.EXTRAS]: "email",
  [SECTION_TYPES.BOOKING_LISTING]: "uuid",
  [SECTION_TYPES.SUBSCRIPTION_LISTING]: "uuid",
  [SECTION_TYPES.SEGMENTS]: "segment_uuid",
};

export const RTE_VARIABLES = {
  "First Name": "customer_first_name",
  "Last Name": "customer_last_name",
  Country: "customer_country",
  "Phone Number": "customer_phone_number",
};

export const COMMS_FOCUSABLE_ELEMENTS = {
  recipients: "recipients",
  sender_email: "sender_email",
  sender_name: "sender_name",
  subject: "subject",
  rejected_emails: "rejected_emails",
  rte: "rte",
  add_block: "add_block",
  attachments: "attachments",
  header: "header",
  footer: "footer",
};
